.App {
  text-align: center;
 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.textfield__lable {
  color: #f2f2f2 !important;
}

.border-radius {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.totalTable
{
  width: 100%;
  background: #fff;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;

}

.totalTable .MuiTableRow-root td {
  border-bottom: none;
}

.centerAlign-Table .MuiTableCell-head{
  font-weight: 700 !important;
  text-align: center !important;
}

.centerAlign-Table .MuiTableCell-body{
  color: '#00104B' !important;
  font-size:14px !important;
  /* font-weight: 600 !important; */
  text-align: center !important;
}

/* .multistep-form-div {
  margin-bottom: 35px;
} */

.multistep-form {
  padding: 0px;
  overflow: hidden;
  counter-reset: step;
  width: 114%;
  display: flex;
  justify-content: space-between;
  margin-left: -6%;
}

.multistep-form li {
  list-style-type: none;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #EDB500;
  text-transform: uppercase;
  font-family: Inter-SemiBold, sans-serif;
  position: relative;
  text-align: center;
}

.logoutIcon{
  box-shadow: 1px 1px 8px #696969;
}
.logoutIcon .MuiSvgIcon-root{
  font-size: larger !important;
  
}
.multistep-form li .step-icon {
  display: block;
  /* margin-bottom: 8px; */
}

.multistep-form li:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid #EDB500;
  display: block;
  background: #EDB500;
  border-radius: 50%;
  margin: 6px auto 0px auto;
  position: relative;
  z-index: 9;
}

.multistep-form li:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #EDB500;
  position: absolute;
  left: 0%;
  bottom: 4px;
  z-index: 0;
}

.multistep-form li.active {
  color: #0067B2;
}

.multistep-form li.active:after {
  border-color: #0067B2;
}

.multistep-form li.active:before {
  background-color: #0067B2;
}

.multistep-form li:first-child:before {
  width: 50%;
  left: 50%;
}

.multistep-form li:last-child:before {
  width: 50%;
}
.tableRow .MuiTableCell-root{
  border-right: 1px solid #E0E0E0 !important;
}

@media only screen and (max-width: 575px) {
  .multistep-form {
      width: 120%;
      margin-left: -10%;
  }
  .step-name{
    display: none;
  }
}

.homePageSlider img{
  width : 100%;
  
}

.parentContainer{
  height:100%;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .fullWidthLink {
    width: "400px";
  }
}
/* Component -> transaction table */

.transactionTable-tablebody {
  max-height: 300px;
  height: 60px;
  overflow-y: scroll;
}

.transaction-cell-status {
  font-size: 14px;
  font-weight: 500;
}
.transaction-cell-status .MuiBox-root { 
  /* color: #00A62E; */
  display: flex;
  align-items: center;
}

.transaction-cell-status .MuiBox-root .MuiSvgIcon-root {
  margin-right: 5px;
}

.transaction-cell-receipt a {
  color: #0067B2;
  text-decoration: none;
}

.transaction-cell-receipt a img {
  width: 25px;
  margin-right: 8px;
}

.transactionTable-emptyRow{
  height: calc(53px * 3);
}

.transactionTable-footer{
  height: 75px;
}


/* Component -> stepper */
.stepper-container{
  padding-left: 20px;
  padding-right: 20px;
}
.multistep-form .MuiTypography-root{
  font-size: 13px;
  font-weight: 700;
}


/* Credit benefit */
.creditBenefitContainer{
  min-width: 250px;
}
 .mb-2{
  margin-bottom: 15px;
}

.cred-flex{
  display: flex;
  align-items: center;
}
.cred-flex img{
  width: 60px;
  margin-right: 10px;
}

.textLeft{
  text-align: left;
}

.cred-fontStyle{
  color: #00365C;
  font-size: 13px;
  font-weight: bold;
}

.cred-desc-style{
  color: #00365C;
  font-size: 12px;
}

/* Deals Page */

.deals-linkStyle{
  font-size: 0.890rem;
  font-weight: 700;
  color: #f2f2f2;
  background: #0067B2;
  padding: 9px 16px;
  border-radius: 5px;
  text-decoration: none;
  white-space: nowrap;
}

.deals-linkStyle:hover, .quickPaytext:hover{
  background: #05548d !important;
}

.deals-imgStyle{
  width: 70px;
  margin-right: 8px;
}

.text-center{
  text-align: center;
}

.dealsContainer{
  margin-bottom: 10px;
  min-width: 375px;
}

.dealsDetail-container{
  display: flex;
  justify-content: space-between;
  padding: 8px; 
  padding-top: 12px; 
  background-color: #0067B2;
}

.civilId{
  font-size: 1.1rem;
  font-weight: 700;
  color: #f2f2f2;
}

.dealTitle{
  font-weight: 700;
  color: #f2f2f2;
  font-size: 1.2rem;
}

.quickPaytext {
  /* font-weight: 700; */
  font-size: 0.890rem !important ;
  color: #f2f2f2 !important;
  background: #0067B2 !important;
  padding: 10px !important;
  border-radius: 4px;
}

.linkImgStyle{
  width: 18px !important;
}

.dealsTable-container{
  width: 100%;
  border: 1px solid #D0DAE0;
  background: #fff;
}

.dealsSelectTitle
{
  font-weight: 700;
  color: #0067B2;
  white-space: nowrap;
}

.quickpayContainer{
  display: flex;
  padding: 24px 24px !important; 
  border: 1px solid #D0DAE0;
  background: #fff;
}

.quickPayButton{
  min-width: 100px;
  /* white-space: nowrap; */
}
.quickPayTypo{
  /* text-align: left; */
  color: #747677;
  font-size: 11px !important;
}
/* Installments */
.installmentHeader{
  display: flex;
  justify-content: space-between;
  padding: 25px; 
  padding-top: 24px;
  background-color: #0067B2;
}

.installmentDetailsHeader{
  font-size: 1.2rem;
  font-weight: 700;
  color: #f2f2f2;
}

.selectInstallmentTitle{
  display: flex;
  justify-content: space-between;
  padding: 24px; 
  padding-top: 24px; 
  border: 1px solid #D0DAE0;
  background: #fff;
}

.textStyle{
  font-size: 18px;
  font-weight: 700;
  color: #0067B2;
}

.tableWidthBg{
  width: 100%;
  background: #fff;
}

.totalTableBox{
  display: flex;
  justify-content: flex-end;
  background: #fff;
}

.insTable{
  width: 35%;
  border: none;
}

.tablecellLeft{
  font-size: 16px;
  color: #0067B2;
  font-weight: bold;
}

.tablecellRight{
  font-size: 16px;
  color: #00104B;
  font-weight: 600;
  white-space: nowrap;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insBackButtonBox{
  display: flex;
  justify-content: space-between;
  padding: 32px;
  padding-top: 20px;
  background: #fff;
}

.justify-item{
  justify-content: flex-start;
  align-items: center;
}

.insLinkStyle{
  font-size: 0.890rem;
  font-weight: 700;
  color: #0067B2;
  background: #fff;
  padding: 12px 25px;
  border: 1px solid #0067B2;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.insLogoBox{
  font-size: 15px;
  font-weight: 500;
  color: #0067B2;
  display: flex;
  align-items: center;
}

.insLogoBox p{
  white-space: nowrap;
  margin-left: 15px;
  margin-right: 5px;
}

.insLogoBox img{
  width: 40px;
  margin-right: 40px;
  margin-left: 10px;
}

.payLink{
  font-size: 0.990rem !important;
  font-weight: 700 !important;
  color: #fff !important;
  background: #0067B2 !important;
  padding: 12px !important;
  border: 1px solid #0067B2 !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  width: 100% !important;
  display: block !important;
}

.trns-tableDiv{
  width: 100%;
  background: #fff;
  padding: 25px 40px;
}

.trns-HeaderTitle{
  font-weight: 700;
  color: #f2f2f2;
}

.trans-civilID{
  font-size: 0.990rem;
  font-weight: 700;
  color: #f2f2f2;
}

.headerSubheaderStyle{
  display: flex;
  justify-content: space-between;
  padding: 24px 32px; 
  /* padding-top: 32px; */
}

.headerBgColor{
  background-color: #0067B2;
}

.headerColor{
  color: #f2f2f2;
}
.subheaderColor{
  color: #0067B2;
}

.fontWeight700{
  font-weight: 700;
}
.subheaderBgColor
{
  background: #fff;
}


.trns-subHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px; 
  /* padding-top: 32px;  */
  border: 1px solid #D0DAE0;
  background: #fff;
}

.trns-subheaderText{
  font-size: 16px;
  font-weight: 700;
  color: #0067B2;
}

.trns-refreshBox .MuiButtonBase-root{
  padding-top: 8px; 
  height: 50px;
  color: #ffffff;
  background-color: #0067B2;
  box-shadow: none;
}
.trns-refreshBox .MuiButtonBase-root span{
  margin-top: 8px;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .refreshText {
    display: none;
  }
}

.trans-tableDiv{
  width: 100%;
  background: #fff;
  padding: 25 px 40px !important;
}

.trns-backBoxContainer{
  justify-content: space-between;
  padding: 32px; 
  background: #fff;
}

.trns-backBoxContainer .MuiBox-root{
  font-size: 15px;
  font-weight: 700;
  color: #0067B2;
}

.trns-backBoxContainerLink{
  font-size: 0.890rem;
  font-weight: 700;
  color: #0067B2;
  background: #fff;
  padding: 12px 25px;
  border: 1px solid #0067B2;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.MuiTextField-root label.Mui-focused {
  color: #f2f2f2 !important;
}

.MuiTextField-root .MuiInput-underline:after {
  border-bottom-color: #f2f2f2 !important;
}

.MuiTextField-root .MuiOutlinedInput-root fieldset {
  border-color: #f2f2f2 !important;
}

.MuiTextField-root .MuiOutlinedInput-root:hover fieldset {
  border-color: #f2f2f2 !important;
}

.MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #f2f2f2 !important;
}



/* 
.MuiTextField-root label.Mui-focused{
  color: #f2f2f2 !important;
}

.MuiTextField-root.MuiInput-underline:after{
  border-bottom-color: #f2f2f2 !important
}
.MuiTextField-root.MuiOutlinedInput-root fieldset{
  border-color: #f2f2f2 !important;
}
.MuiTextField-root.MuiOutlinedInput-root fieldset:hover{
  border-color: #f2f2f2 !important;
}
.MuiTextField-root.MuiOutlinedInput-root .Mui-focused fieldset{
  border-color: #f2f2f2 !important;
} */

.dealsheaderSubheaderStyle{
  display: flex;
  justify-content: space-between;
  /* padding: 24px 32px;  */
  /* padding-top: 32px; */
  /* padding-left: 2rem;
  padding-right: 0.5rem; */
}

.profileEmail input{
  border: 1px solid rgba(0, 0, 0);

}

.profileTable .MuiTableCell-root{
  border:none;
}
.borderStyle {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.borderStyleEmail {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.borderStyleEmail div{
  height: 40px !important;
}

.clickHereLink{
  color: #408DC5;
}

.pillShapeHeader{
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.headTextColor .MuiTableCell-root{
  color : #FFF;
}

.receiptDateSection{
  background: #DAF2FF !important;
  padding: 4px 5px 10px 20px !important;
  position: relative !important;
  /* right: -40px !important; */
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  height: 25px !important;
}

@media screen and (max-width: 599px) {
  .receiptDateSection{
    padding: 4px 5px 10px 10px !important;
    height: 50px !important;
  }
  .dealTitle {
    font-size: 0.9rem; 
  }
  .textStyle, .trns-subHeader {
    font-size: 16px !important; 
  }
  .installmentDetailsHeader {
    font-size: 0.9rem;
    text-align: left;
  }
  .trns-HeaderTitle {
    text-align: left;
  }
  .civilId{
    font-size: 0.890rem;
  }
}
.MuiDialogContent-root {
padding-left: 24px !important;
padding-right: 24px !important;
}

.ReceiptTransactionTable thead tr th {
  font-weight: 700;
  border-bottom: none;
  padding: 5px 16px;
  background: rgb(5, 93, 156);
}

.ReceiptTransactionTable thead tr th:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.ReceiptTransactionTable thead tr th:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.MuiDialog-paper	{
  overflow-x:hidden !important;
}

.receiptModal .MuiDialogContent-root{
  padding: 0px !important;
}

.receiptModal .MuiPaper-root .MuiPaper-elevation{
  width: 520px !important;
}
.mainContainer {
  margin-top: 42px !important;
}
.pad-right-10 {
  padding-right: 10px !important;
}
@media (max-width: 761px)
{
  .mainContainer {
      margin-top: 210px !important;
  }
}

/* CSS styling for before/after/avoid. */
.break-before {
  page-break-before: always;
}

.break-after {
  page-break-after: always;
}

.break-avoid {
  page-break-inside: avoid;
}

.fullpage {
  height: 40pt;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.color-blue {
  color: #0067B2;
}


.error-msg{
  visibility: visible;
}

.no-error-msg{
  visibility: hidden;
}




@media (max-width: 450px) {
  .recaptcha-wrapper {
    transform: scale(1.12) !important; 
  }
}

@media (max-width: 374px) {
  .recaptcha-wrapper {
    transform: scale(1.12) !important;  
  }
}

@media (max-width: 320px) {
  .recaptcha-wrapper {
    transform: scale(1.12) !important; 
  }
}




